import logo from './logo.png';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<p>
					Welcome to Tea Tree
				</p>
			</header>
		</div>
	);
}

export default App;
